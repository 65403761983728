import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SettingsProvider } from 'contexts/SettingsContext';
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
// import runMocks from './api/mock';

// ----------------------------------------------------------------------

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN || '',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.3,
  tunnel: import.meta.env.VITE_SENTRY_TUNNEL || '',
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    // TODO: Temporary Fix until migration to subframe, The resizeobserver
    //  error is triggered from dependency juggle/resize-observer used by simplebar
    // more to this, https://github.com/juggle/resize-observer/issues/103#issuecomment-610221438
    'ResizeObserver loop completed with undelivered notifications',
  ],
});

// if (process.env.NODE_ENV === 'development') {
//  runMocks();
// }

ReactDOM.render(
  <HelmetProvider>
    <SettingsProvider>
      <CollapseDrawerProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CollapseDrawerProvider>
    </SettingsProvider>
  </HelmetProvider>,
  document.getElementById('root'),
);
