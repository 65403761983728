import LoadingScreen from 'components/LoadingScreen';
import useUserAccountState from 'hooks/useUserAccountState';
import { ReactNode } from 'react';
import { StatsigProvider } from 'statsig-react';

type StatsigProviderProps = {
  children: ReactNode;
  sdkKey: string;
};

export function EnhancedStatsigProvider({
  children,
  sdkKey,
}: StatsigProviderProps) {
  const {
    user,
    account,
    currentOrganization: organization,
  } = useUserAccountState();

  let userEmail = user.email;

  return (
    <StatsigProvider
      sdkKey="client-z4QujFaRdu7qg0NxSeelAo35ByydHCE3kTMz7h9dgV0"
      user={{
        userID: userEmail,
        email: userEmail,
        custom: {
          name: user.name,
          website: location.host,
          accountId: account?.accountId,
          orgId: organization?.id,
        },
      }}
      waitForInitialization={true}
      initializingComponent={<LoadingScreen />}
    >
      {children}
    </StatsigProvider>
  );
}
