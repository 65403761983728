import './components/index.css';
import Router from './routes';
import { Suspense } from 'react';
import ThemeProvider from './theme';
import RtlLayout from 'components/RtlLayout';
import ScrollToTop from 'components/ScrollToTop';
import ThemeColorPresets from 'components/ThemeColorPresets';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import NotistackProvider from 'components/NotistackProvider';
import { AmplitudeProvider } from 'contexts/AmplitudeContext';
import { DialogDetailsProvider } from './contexts/DialogsContext';
import * as Sentry from '@sentry/react';
import { ErrorHandler } from 'components/ErrorBoundary';
import { EnhancedStatsigProvider } from 'contexts/StatsigContext';
import { useLocation } from 'react-router';
import RouteSkeletons from 'components/Skeletons/RouteSkeletons';
import { PostHogProvider } from 'contexts/PostHogContext';
import { UserAccountProvider } from 'contexts/UserAccountContext';

export default function App() {
  const location = useLocation();
  const pathname = location.pathname.toString();

  return (
    /* Theme Provider */
    <ThemeProvider>
      <ThemeColorPresets>
        <Sentry.ErrorBoundary fallback={ErrorHandler}>
          <RtlLayout>
            <UserAccountProvider>
              <NotistackProvider>
                <AmplitudeProvider>
                  <PostHogProvider>
                    <EnhancedStatsigProvider sdkKey="client-z4QujFaRdu7qg0NxSeelAo35ByydHCE3kTMz7h9dgV0">
                      <MotionLazyContainer>
                        <ScrollToTop />
                        <DialogDetailsProvider>
                          <Suspense
                            fallback={<RouteSkeletons pathname={pathname} />}
                          >
                            <Router />
                          </Suspense>
                        </DialogDetailsProvider>
                      </MotionLazyContainer>
                    </EnhancedStatsigProvider>
                  </PostHogProvider>
                </AmplitudeProvider>
              </NotistackProvider>
            </UserAccountProvider>
          </RtlLayout>
        </Sentry.ErrorBoundary>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
