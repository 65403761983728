import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Inter, Helvetica, Arial, sans-serif';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 400,
    lineHeight: 80 / 64,
    letterSpacing: 1,
    ...responsiveFontSizes({
      sm: 16,
      md: 18,
      lg: 20,
    }),
  },
  h2: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 64 / 48,
    ...responsiveFontSizes({
      sm: 14,
      md: 16,
      lg: 18,
    }),
  },
  h3: {
    fontWeight: 400,
    ...responsiveFontSizes({
      sm: 14,
      md: 16,
      lg: 18,
    }),
  },
  h4: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    ...responsiveFontSizes({
      sm: 12,
      md: 14,
      lg: 16,
    }),
  },
  h5: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    ...responsiveFontSizes({
      sm: 8,
      md: 12,
      lg: 14,
    }),
  },
  h6: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    ...responsiveFontSizes({
      sm: 8,
      md: 10,
      lg: 12,
    }),
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: pxToRem(14),
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: pxToRem(12),
  },
  body1: {
    fontWeight: 400,
    fontSize: pxToRem(16),
  },
  body2: {
    fontWeight: 400,
    fontSize: pxToRem(14),
  },
  caption: {
    fontWeight: 400,
    fontSize: pxToRem(12),
  },
  overline: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
} as const;

export default typography;
