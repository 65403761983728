// @mui
import { useTheme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';
// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function GetFontValue(variant: Variant) {
  const theme = useTheme();
  const breakpoints = useWidth();

  const key = theme.breakpoints.up(breakpoints === 'xl' ? 'lg' : breakpoints);

  const hasResponsive =
    variant === 'h1' ||
    variant === 'h2' ||
    variant === 'h3' ||
    variant === 'h4' ||
    variant === 'h5' ||
    variant === 'h6';

  const getFont: any =
    hasResponsive && theme.typography[variant][key]
      ? theme.typography[variant][key]
      : theme.typography[variant];

  const fontSize = remToPx(getFont.fontSize);
  const lineHeight = Number(theme.typography[variant].lineHeight) * fontSize;
  const { fontWeight, letterSpacing } = theme.typography[variant];

  return { fontSize, lineHeight, fontWeight, letterSpacing };
}

// ----------------------------------------------------------------------

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({
  sm,
  sm1,
  sm2,
  sm3,
  md,
  md1,
  md2,
  lg,
  lg1,
  lg2,
  lg3,
}: {
  sm: number;
  sm1?: number;
  sm2?: number;
  sm3?: number;
  md: number;
  md1?: number;
  md2?: number;
  lg: number;
  lg1?: number;
  lg2?: number;
  lg3?: number;
}) {
  return {
    '@media (min-width:500px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm1 ? sm1 : sm),
    },
    '@media (min-width:700px)': {
      fontSize: pxToRem(sm2 ? sm2 : sm),
    },
    '@media (min-width:800px)': {
      fontSize: pxToRem(sm3 ? sm3 : sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1000px)': {
      fontSize: pxToRem(md1 ? md1 : md),
    },
    '@media (min-width:1100px)': {
      fontSize: pxToRem(md2 ? md2 : md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
    '@media (min-width:1300px)': {
      fontSize: pxToRem(lg1 ? lg1 : lg),
    },
    '@media (min-width:1400px)': {
      fontSize: pxToRem(lg2 ? lg2 : lg),
    },
    '@media (min-width:1500px)': {
      fontSize: pxToRem(lg3 ? lg3 : lg),
    },
  };
}

// ----------------------------------------------------------------------

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    // @ts-ignore not sure what is this
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useResponsive('up', key);
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
